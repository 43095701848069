import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "kornys-polyglot-code-tools",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#kornys-polyglot-code-tools",
        "aria-label": "kornys polyglot code tools permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Korny's Polyglot Code Tools`}</h1>
    <p><img parentName="p" {...{
        "src": "banner.png",
        "alt": "banner alt text"
      }}></img></p>
    <p>{`The Polyglot Code Tools are a suite of free open-source apps for analysing and visualising large software projects.`}</p>
    <p>{`They provide multiple ways to visualise code written a wide range of programming languages (hence "polyglot").  Most of the tools will work for `}<em parentName="p">{`any`}</em>{` programming language, as long as it is text-based!`}</p>
    <p><strong parentName="p">{`This site is a work in progress!`}</strong>{` - if there is something you'd like to see in more detail, contact me (or I guess you could raise an issue on github)`}</p>
    <p>{`The best place to start is the `}<a parentName="p" {...{
        "href": "/overview"
      }}>{`Overview`}</a></p>
    <p>{`There is also a `}<a parentName="p" {...{
        "href": "/news"
      }}>{`news page`}</a>{` for recent changes`}</p>
    <p>{`These docs are published to `}<a parentName="p" {...{
        "href": "https://polyglot.korny.info"
      }}>{`https://polyglot.korny.info`}</a></p>
    <p>{`Source code for this site is at `}<a parentName="p" {...{
        "href": "https://github.com/kornysietsma/polyglot-tools-docs"
      }}>{`https://github.com/kornysietsma/polyglot-tools-docs`}</a></p>
    <p>{`The site is built with `}<a parentName="p" {...{
        "href": "https://rocketdocs.netlify.app/"
      }}>{`The Rocketseat Gatsby theme for documentation websites`}</a></p>
    <h2 {...{
      "id": "about-me",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#about-me",
        "aria-label": "about me permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`About me`}</h2>
    <p>{`I'm Korny Sietsma, an Australian software developer living in the UK.`}</p>
    <p>{`My main info site is at `}<a href="https://korny.info">{`korny.info`}</a></p>
    <p>{`My blog is at `}<a href="https://blog.korny.info">{`blog.korny.info`}</a></p>
    <p>{`You can contact me on `}<a rel="me" href="https://octodon.social/@Korny">{`Mastodon`}</a>{` or `}<a href="https://twitter.com/kornys">{`Twitter`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      